html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}
*, *:before, *:after {
	box-sizing: inherit;
}

::selection{
	background-color: rgba(242,0,0,.45);
}

body{
	margin: 0px;
	padding: 0px;
	background-color: black;
	font-family: 'EB Garamond', serif;
	font-size: 14pt;
}

/* unvisited link */
a:link {
  color: #f00e;
}

/* visited link */
a:visited {
  color: #ff7500cc;
}

/* mouse over link */
a:hover {
  color: #f00;
}


@font-face {
    font-family: "Skyline";
    src: url(../assets/skyline.ttf) format("truetype");
}

.back-to-top{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	z-index: 100;
	height: 50px;
	width: 50px;
	right: 10px;
	left: auto;
	top: 10px;
	background: grey;
	opacity: .3;
	transition: opacity .2s;
}

.back-to-top:hover{
	opacity: .8;
}

.up-arrow{
	flex-basis: 48%;
	width: 90%;
}

#collage{
	position: absolute;
	z-index: -10;
}

#collage-overlay{
	position: absolute;
	z-index: 1;
	background-color: rgba(0,0,0,.5);
	width: 100%;
	height: 100%;
}

.hero{
	color: white;
	width: 100%;
	height: 500px;

	margin: 0px;
	padding: 0px;
	position: relative;
	overflow: hidden;
}

.hero-text{
	padding: 20px;
}

.logo{
	flex-shrink: 0;
	height: 100px;
	width: 100px;
	border-radius: 50%;
	border: solid;
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo-type{
	margin: 0;
	font-family: "Skyline", serif;
	font-size: 5.5em;
	font-weight: normal;
}

.white{
	background-color: white;
	color: black;
}

.black{
	background-color: black;
	color: white;
}

h2{
	font-family: "Skyline", serif;
	font-size: 4em;
	font-weight: normal;
	text-align: center;
	margin: 0;
	/*padding: 60px;*/
	padding: 0;
	letter-spacing: 1px;
	border-top: solid;
	border-bottom: solid;
	border-width: .5px;
}

h3{
	font-family: "Skyline", serif;
	font-size: 3em;
	font-weight: normal;
	margin: 20px 0;
	padding: 0;
}

.title{
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
}

.title-type{
	flex-shrink: 1;
	font-size: 6em;
	font-weight: normal;
	margin: 0 0 0 20px;
	font-family: "Skyline", serif;
}

.subtitle-type{
	flex-shrink: 1;
	font-family: 'EB Garamond', serif;
	font-size: 14pt;
	margin: 0 0 0 20px;
	padding: 10px 0px;
	border-top: solid .5px;
}

.nav{
	font-size: 1.2em;
	display: inline-block;
	margin: 30px 0 0 0;

	a{
		color: white;
	}

	a:link{
		color: white;
	}

	a{
		text-decoration: none;
	}

	ul{
		list-style-type: none;
		margin: 16px 0;
		padding: 0;
	}

}

.nav > ul > li{
	padding: 5px 0;
	border-top: solid .5px;
}
.nav > ul > li:first-child{
	border-top: none;
}

.social-icons{
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: flex-start;
}

.icon{
	display: inline-block;
	margin: 0px 10px;
	padding: 0;

}

.section{
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	padding: 50px;
}

.ltr{
	flex-direction: row;
}

.rtl{
	flex-direction: row-reverse;
}

blockquote{
	padding: 20px;
	background-color: rgba(127, 127, 127, .5);
}

.quote{
	text-align: center;
	margin-bottom: 20px;
}

.cite{
	text-align: left;
}

.bookdiv {
	width: 100%;
	text-align: center;
	margin: 0;
}
.bookdiv hr{
	width: 200px;
	max-width: 80%;
	margin: 0 auto;
}

.text{
	flex-grow: 1;
	max-width: 800px;
	flex-basis: 350px;
}

.short{
	padding-bottom: 0;
}
.short-top{
	padding-top: 10px;
}

.cover{
	width: 330px;
	height: auto;
	flex-basis: auto;
	margin: 20px;
}

@media only screen and (max-width: 700px) {
	.cover{
		margin: 0px;
		width: 330px;
		max-width: 100%;
		height: auto;
	}
	.section{
		padding: 25px;
	}
	.title{
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.title-type{
		font-size: 3.5em;
		margin: 10px 0;
		text-align: center;
		line-height: .8em;
	}
	.subtitle-type{
		text-align: center;
		margin: 0;
	}
	.logo{
		margin-right: 10px;
	}
	.nav{
		display: block;
		margin: auto;
		width: 100%;
		text-align: center;
	}
	.social-icons{
		justify-content: center;
	}
	blockquote{
		margin: 0;
	}
}
